<template>
  <el-collapse v-model="activeNames" class="index-cooperation">
    <el-collapse-item
      v-for="item in list"
      :key="item.id"
      :title="item.cooperation_channel"
      :name="item.id"
    >
      <div v-for="itemx in item.coupons" :key="itemx.id">
        <coupon-item :isCooperation="true" :item="itemx" />
      </div>
    </el-collapse-item>
    <!-- <el-collapse-item title="小揚麵店" name="2">
      <div>
        <coupon-item />
      </div>
    </el-collapse-item> -->
  </el-collapse>
</template>

<script>
import { ref } from "vue";
import CouponItem from "@/components/CouponItem.vue";
export default {
  components: {
    CouponItem,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const activeNames = ref([]);

    return {
      activeNames,
    };
  },
};
</script>

<style lang="scss" scoped>
.index-cooperation {
  margin: 0 -15px;

  :deep(.el-collapse-item__header) {
    padding: 0 15px;
  }

  :deep(.el-collapse-item__content) {
    background-color: #eaeaea;
    & > div {
      margin-bottom: 8px;
    }
  }

  :deep(.el-collapse-item__content) {
    padding: 15px;
  }
}
</style>
