<template>
  <section class="member-menu pt-2">
    <div class="menu-block rounded-lg">
      <div
        class="row no-gutters justify-content-center align-items-center"
        v-if="
          !shop_type.includes('okshop+s') && !shop_type.includes('okshop+os')
        "
      >
        <div class="col border-right pt-4 pb-4" v-if="
            shop_dm_mode_setting == null || 
            shop_dm_mode_setting.data[0].create_order===1 ||
            shop_dm_mode_setting.data[0].order_list===1
        ">
          <h3 class="text-center">餐飲</h3>
        </div>
        <div class="col"  v-if="
            shop_dm_mode_setting == null || 
            shop_dm_mode_setting.data[0].create_order===1
        ">
          <router-link to="/home">
            <div class="icon">
              <img
                src="@/assets/images/mainMenu-onlineOrder.svg"
                alt="立即點餐"
              />
            </div>
            <span>立即點餐</span>
          </router-link>
        </div>

        <div class="col"  v-if="
            shop_dm_mode_setting == null || 
            shop_dm_mode_setting.data[0].order_list===1
        ">
          <router-link to="/orders">
            <div class="icon">
              <img src="@/assets/images/mainMenu-iconCart.svg" alt="消費紀錄" />
            </div>
            <span>消費紀錄</span>
          </router-link>
        </div>
      </div>

      <div
        class="row no-gutters justify-content-center align-items-center"
        v-if="
          shop_type === 'okshop+dfs' ||
          shop_type === 'okshop+ds' ||
          shop_type === 'okshop+s' ||
          shop_type === 'okshop+os'
        "
      >
        <div class="col border-right pt-4 pb-4" v-if="
            shop_dm_mode_setting == null || 
            shop_dm_mode_setting.data[1].create_order===1 ||
            shop_dm_mode_setting.data[1].order_list===1
        ">
          <h3 class="text-center">零售</h3>
        </div>
        <div class="col" v-if="shop_dm_mode_setting == null || shop_dm_mode_setting.data[1].create_order===1">
          <router-link to="/shop/category">
            <div class="icon retail-color">
              <img
                src="@/assets/images/mainMenu-iconStore.svg"
                alt="零售商店"
              />
            </div>
            <span>零售商店</span>
          </router-link>
        </div>

        <div class="col" v-if="shop_dm_mode_setting == null || shop_dm_mode_setting.data[1].order_list===1">
          <router-link to="/shop/orders">
            <div class="icon retail-color">
              <img
                src="@/assets/images/mainMenu-iconOrder.svg"
                alt="訂單記錄"
              />
            </div>
            <span>訂單記錄</span>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  computed,
  inject,
  onMounted,
  reactive,
  ref,
  onBeforeUpdate,
  watch,
} from "vue";

import storage from "@/utils/storage";
import { useStore } from "vuex";
export default {
  props: {
    shop_type: {
      type: String,
      default: "",
    },
  },
  setup() {
    const store = useStore();
    const shop_dm_mode_setting = computed(() => store.getters["shop/brand_dm_mode_setting"]); // 商店DM模式設定
    // console.log(shop_dm_mode_setting)
    return {
      shop_dm_mode_setting,
    };
  },
};
</script>

<style lang="scss" scoped>
.text-center {
  font-size: 20px;
}
.retail-color {
  background-color: #e0a471 !important;
}
</style>
