<template>
  <div class="index-qrcode">
    <qrcode-vue
      :value="qrcode_value"
      :size="110"
      :level="qrcode_level"
      :renderAs="qrcode_renderAs"
      @click="itemClick"
      class="small-code"
      v-if="qrcode_value"
    />
    <el-dialog
      v-model="dialogVisible"
      title="會員QRCode"
      :before-close="() => (dialogVisible = false)"
      center
      width="30%"
    >
      <div class="dialog-content">
        <qrcode-vue
          :value="qrcode_value"
          :size="200"
          :level="qrcode_level"
          :renderAs="qrcode_renderAs"
          :class="qrcode_class"
        />
      </div>
      <div class="text">請交由店員掃描</div>

      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="dialogVisible = false">
            確認
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref } from "vue";
import QrcodeVue from "qrcode.vue";
export default {
  components: {
    QrcodeVue,
  },
  props: {
    qrcode_value: {
      type: String,
      default: "",
    },
    qrcode_level: {
      type: String,
      default: "L",
    },
    qrcode_renderAs: {
      type: String,
      default: "canvas",
    },
    qrcode_class: {
      type: String,
      default: "qrcode",
    },
  },
  setup() {
    const dialogVisible = ref(false);

    const itemClick = () => {
      dialogVisible.value = true;
    };

    return {
      dialogVisible,
      itemClick,
    };
  },
};
</script>

<style lang="scss" scoped>
.index-qrcode {
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
  .small-code {
    cursor: pointer;
  }
}
.index-qrcode::v-deep .el-dialog {
  min-width: 300px;

  .el-dialog__body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .dialog-content {
      border: 1px solid var(--color-dark);
      padding: 20px;

      .text {
        text-align: center;
      }
    }
  }

  .dialog-footer {
    .el-button {
      width: 100%;
    }
  }
}
</style>
