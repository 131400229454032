<template>
  <div>
    <img :src="src" alt="" class="avatar rounded-circle" @error="noAvatar" />
  </div>
</template>

<script>
import errAvatar from "@/assets/images/empty-logo-vertical.svg";

export default {
  props: {
    src: {
      type: String,
    },
  },
  methods: {
    noAvatar(e) {
      e.target.src = errAvatar;
    },
  },
};
</script>
