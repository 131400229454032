<template>
  <div class="coupon-list">
    <list-wrapper
      :list="list"
      :getList="getFetch"
      :pagination="pagination"
      :initLoading="initLoading"
      :window="true"
      info="目前尚無優惠券"
    >
      <template v-if="currentBar === '/joint'">
        <IndexCooperationList :list="list" />
      </template>
      <template v-else>
        <coupon-item v-for="item in list" :key="item.id" :item="item" />
      </template>
    </list-wrapper>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import CouponItem from "@/components/CouponItem.vue";
import CouponService from "@/services/coupon.service";
import ListWrapper from "@/components/ListWrapper.vue";
import IndexCooperationList from "./IndexCooperationList.vue";

export default {
  components: {
    CouponItem,
    ListWrapper,
    IndexCooperationList,
  },
  props: {
    currentBar: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const activeNames = ref([]);
    const list = ref([]);
    const pagination = ref({});
    const initLoading = ref(true);

    const getFetch = (page) => {
      let fetch;
      if (props.currentBar === "/online") {
        fetch = CouponService.getCoupons(1, page, 5);
      } else if (props.currentBar === "/joint") {
        fetch = CouponService.getCooperations(page, 5);
      } else {
        fetch = CouponService.getCoupons(0, page, 5);
      }
      return fetch.then((res) => {
        pagination.value = res?.pagination;
        if (res.data?.length) {
          list.value.push(...res.data);
        }
      });
    };

    const initList = async () => {
      initLoading.value = true;
      list.value = [];
      await getFetch(1);
      initLoading.value = false;
    };

    watch(
      () => props.currentBar,
      async () => {
        await initList();
      },
      {
        immediate: true,
      }
    );

    return {
      activeNames,
      list,
      getFetch,
      pagination,
      initLoading,
    };
  },
};
</script>

<style lang="scss" scoped>
.coupon-list {
  .coupon-item {
    margin-bottom: 12px;
  }

  :deep(.copy-right) {
    margin: 0 -15px;
  }
}
</style>
