<template>
  <div class="member-descibe">
    <div class="member-descibe-right">
      <member-avatar class="member-avatar" :src="currentUser.avatar" />
      <div>
        <div v-if="Number(currentProfile.is_vip)">
          <img src="@/assets/images/Group1474.svg" alt="icon" />
          VIP會員
        </div>
        <div v-else>一般會員</div>
        <div class="line-name">{{ currentUser.name }}</div>
      </div>
    </div>
    <div class="info-button">
      <router-link to="/profile" class="base-info">
        <span>基本資料</span>
        <div class="center">
          <img src="@/assets/images/edit_black_24dp.svg" alt="基本資料" />
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import MemberAvatar from "@/components/MemberAvatar";
export default {
  components: {
    MemberAvatar,
  },
  props: {
    currentUser: {
      type: Object,
      default: () => ({}),
    },
    currentProfile: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.member-descibe {
  &-right {
    display: flex;
    padding: 16px 0;

    .member-avatar {
      width: 70px;
      height: 70px;
      margin-right: 16px;
    }

    .line-name {
      font-size: 16px;
    }
  }

  .info-button {
    display: flex;

    .base-info {
      display: flex;
      align-items: center;
      padding: 8px 24px;
      border: 1px solid #fff;
      border-radius: 10px;

      span {
        color: var(--color-white);
        margin-right: 8px;
      }
    }
  }
}
</style>
