<template>
  <div
    class="index-point rounded-lg shadow bg-white row no-gutters"
    @click="pointClick"
  >
    <div class="col-4 border-right text-center">
      <img src="@/assets/images/point-icon.svg" alt="我的點數" />
      <p class="text-center pt-1 mb-0">我的點數</p>
    </div>
    <div class="col center">
      <h2 class="text-main text-center">
        {{ formatMoney(Number(currentProfile.points) || 0) }}
        <span style="font-size: 16px">點</span>
      </h2>
      <i class="el-icon-arrow-right arrow-icon" />
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { formatMoney } from "@/utils/tools";
export default {
  props: {
    currentProfile: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const router = useRouter();

    const pointClick = () => {
      router.push("/point");
    };

    return {
      pointClick,
      formatMoney,
    };
  },
};
</script>

<style lang="scss" scoped>
.index-point {
  position: relative;
  top: -15px;
  display: flex;
  padding: 16px 0;
  cursor: pointer;

  .arrow-icon {
    position: absolute;
    right: 12px;
  }
}
</style>
