<template>
  <div>
    <div class="member-top container">
      <index-info :currentUser="currentUser" :currentProfile="currentProfile" />
      <div class="member-code">
        <template v-if="qrcodeValue">
          <index-qrcode :qrcode_value="qrcodeValue" />
          <div class="text">點我放大</div>
        </template>
      </div>
    </div>
    <div class="container">
      <index-point :currentProfile="currentProfile" />
      <index-button :shop_type="shop_type"></index-button>
      <index-coupon></index-coupon>
    </div>
    <!-- <copy-right /> -->
  </div>
</template>

<script>
import { inject, computed } from "vue";
import { useStore } from "vuex";

import IndexInfo from "./components/IndexInfo.vue";
import IndexQrcode from "./components/IndexQrcode.vue";
import IndexButton from "./components/IndexButton.vue";
import IndexPoint from "./components/IndexPoint.vue";
import IndexCoupon from "./components/IndexCoupon.vue";

export default {
  components: {
    IndexInfo,
    IndexQrcode,
    IndexButton,
    IndexPoint,
    // CopyRight,
    IndexCoupon,
  },
  emits: ["onLoading"],
  setup() {
    const shop_type = inject("shop_type"); // 取得商店類型:零售/餐飲
    const store = useStore();

    store.dispatch("member/getProfile");
    const currentUser = computed(() => {  // 取得使用者詳細資料
      return store.state.member.profile;
    });
    // console.log(currentUser)
    store.dispatch("member/getHompageProfileAction");
    const currentProfile = computed(() => {  // 取得使用者簡介:姓名、vip、點數、優惠券數量
      return store.state.member.homepage;
    });
    // console.log(currentProfile)

    const qrcodeValue = computed(() => {
      if (Object.keys(currentUser.value).length === 0) return;
      return JSON.stringify({
        type: "member",
        member_id: currentUser.value.id,
      });
    });

    return {
      qrcodeValue,
      shop_type,
      currentUser,
      currentProfile,
    };
  },
};
</script>

<style lang="scss" scoped>
.member-top {
  display: flex;
  color: var(--color-white);
  padding-top: 24px;
  padding-bottom: 36px;
  justify-content: space-around;
  position: relative;
  background: linear-gradient(114deg, #f89321, #fa5700);

  .member-code {
    .text {
      text-align: center;
    }
  }
}
</style>
