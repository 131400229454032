<template>
  <div class="index-coupon">
    <div class="top">
      <span class="left">
        <span class="coupon-icon center">
          <img src="@/assets/images/coupon-icon.svg" alt="icon" />
        </span>
        <span class="text">優惠券</span>
        <span class="count-item">
          共計
          <span class="count">{{ couponCount }}</span>
          張
        </span>
      </span>
      <span class="record" @click="recordClick">歷史紀錄 ></span>
    </div>
    <coupon-bar v-model:currentBar="currentBar" :bars="bars" />
    <index-coupon-list :currentBar="currentBar" />
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import CouponBar from "@/components/CouponBar.vue";
import IndexCouponList from "./IndexCouponList.vue";
import CouponSevice from "@/services/coupon.service";

export default {
  components: {
    CouponBar,
    IndexCouponList,
  },
  setup() {
    const currentBar = ref("");
    const router = useRouter();
    const couponCount = ref("");

    onMounted(async () => {
      const res1 = await CouponSevice.getCoupons(1);
      const res2 = await CouponSevice.getCoupons(0);
      const res3 = await CouponSevice.getCooperations();
      const count1 = res1?.pagination?.total || 0;
      const count2 = res2?.pagination?.total || 0;
      const count3 = res3?.pagination?.total || 0;
      couponCount.value = count1 + count2 + count3;
    });

    const bars = [
      [
        {
          name: "門市優惠券",
          path: "",
        },
        {
          name: "線上優惠券",
          path: "/online",
        },
      ],
      // [
      //   {
      //     name: "跨界優惠券",
      //     path: "/joint",
      //   },
      // ],
    ];

    const recordClick = () => {
      router.push("/coupon-record");
    };

    return {
      bars,
      recordClick,
      currentBar,
      couponCount,
    };
  },
};
</script>

<style lang="scss" scoped>
.top {
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  .left {
    display: flex;
    align-items: baseline;

    .text {
      color: #666666;
    }

    & > * {
      margin: 0 8px;
    }
  }

  .coupon-icon {
    height: 25px;
    position: relative;
  }

  .count-item {
    color: #f89328;
    line-height: 0;

    .count {
      font-size: 32px;
    }
  }
  .record {
    color: #9b9b9b;
    cursor: pointer;
  }
}

.index-coupon {
  background-color: #f8f8f8;
  margin: 0 -15px;
  padding: 0 15px;

  & > div {
    padding-top: 24px;
  }
}
</style>
